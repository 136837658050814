/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ListWraaper} from './containers/List'

export function KycPage() {
  useEffect(() => {}, [])

  return (
    <div>
      <Switch>
        <Route path='/kycVendor/list' exact={true} component={ListWraaper} />
        <Redirect from='/kycVendor' exact={true} to='/kycVendor/list' />
        <Redirect to='/kycVendor/list' />
      </Switch>
    </div>
  )
}
