import React from 'react'
import axios from 'axios'
// import {InventoryModal} from '../models/InventoryModal'
//import {IInventoryFormValues} from '../containers/CreateInventoryForm'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_LOGIN_USER_DATA_URL = `${API_URL}api/v1/auth/admin/details`
export const GET_TERMS_AND_CONDITION_DATA_URL = `${API_URL}/api/v1/auth/admin/static-page-details`

// Get Single Inventory Data
export async function getLoginUserData() {
  try {
    let singleInventoryData = await axios.get(`${GET_LOGIN_USER_DATA_URL}`)
    console.log({singleInventoryData})
    return singleInventoryData
  } catch (e) {
    throw e
  }
}

export async function getTermsAndConditionData() {
  try {
    let getTermsData = await axios.get(
      `${GET_TERMS_AND_CONDITION_DATA_URL}?slug=vendor_terms_and_conditions`
    )
    console.log({getTermsData})
    return getTermsData
  } catch (e) {
    throw e
  }
}
