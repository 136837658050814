import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}v1/auth/get-admin`
export const LOGIN_URL = `${API_URL}api/v1/admin/login/`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}api/v1/admin/reset-password`
export const REQUEST_CHANGE_EMAIL_URL = `${API_URL}api/v1/auth/admin/change-email`
export const REQUEST_CHANGE_PASSWORD_URL = `${API_URL}api/v1/auth/admin/change-password`
export const REQUEST_CHANGE_ADMIN_SETTINGS_URL = `${API_URL}api/v1/auth/admin/update-system-settings`
export const RESET_PASS_OTP_URL = `${API_URL}api/v1/admin/send-verification-otp`
export const EDIT_VENDOR_URL = `${API_URL}api/v1/auth/admin/vendor`

// Server should return AuthModel
export async function login(email: string, password: string) {
  try {
    let loginData = await axios.post(LOGIN_URL, { email, password })
    return loginData.data;
  } catch (e) {
    throw e;
  }
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(values: any) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {...values})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export async function changePassword(old_password: string, new_password: string) {
  try {
    let changePasswordData = await axios.put(REQUEST_CHANGE_PASSWORD_URL, {
      old_password,
      new_password,
    })
    return changePasswordData.data
  } catch (e) {
    throw e
  }
}

export async function changeEmailApi(email: string) {
  try {
    let changePasswordData = await axios.put(REQUEST_CHANGE_EMAIL_URL, {
      email,
    })
    return changePasswordData.data
  } catch (e) {
    throw e
  }
}

export async function changeAdminSettingApi(dataObj: any) {
  try {

    let billing_details_obj: any = {};
    let return_within: string = dataObj.return_within ? dataObj.return_within : ''

    console.log(dataObj,"data")
    billing_details_obj.recipients_name = dataObj.recipients_name ? dataObj.recipients_name : '' 
    billing_details_obj.bank_name = dataObj.bank_name ? dataObj.bank_name : '' 
    billing_details_obj.bank_account_number = dataObj.bank_account_number ? dataObj.bank_account_number : '' 
    billing_details_obj.bank_routing_number = dataObj.bank_routing_number ? dataObj.bank_routing_number : '' 
    billing_details_obj.bank_swift_bic_code = dataObj.bank_swift_bic_code ? dataObj.bank_swift_bic_code : '' 


    let changeSettingData = await axios.put(REQUEST_CHANGE_ADMIN_SETTINGS_URL, {
      billing_details : billing_details_obj,
      return_within
    })
    return changeSettingData.data
  } catch (e) {
    throw e
  }
}

export async function requestToSendOtp(email: any) {
  try {

    let changeSettingData = await axios.put(RESET_PASS_OTP_URL, {
      email
    })
    return changeSettingData
  } catch (e) {
    throw e
  }
}

export async function editVendorData(vendorObject: any) {
  try {
    console.log({vendorObject})
    let editVendorData = await axios.put(`${EDIT_VENDOR_URL}`, {
      ...vendorObject,
    })
    return editVendorData
  } catch (e) {
    throw e
  }
}