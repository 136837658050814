import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import moment from 'moment'
import {Tag, Alert, Button} from 'antd'
import {config} from '../../../comman/constants'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'
// import {getShipMentPriceList} from '../../shipmentPrice/redux/ShipmentPriceCRUD'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
// import {editOrderStatusData} from '../redux/OrderCRUD'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {
  changeOrderStausDataApi,
  getOrderDataForCsv,
  getOrderStockDetails,
  getVendorStockDataForOrder,
  cancelOrderDataApi,
} from '../redux/OrderCRUD'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import {Spin} from 'antd'
import {Popconfirm} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {TruckOutlined} from '@ant-design/icons'
import {faTruck} from '@fortawesome/free-solid-svg-icons'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import {ShipOrderForm} from './ShipOrderForm'

interface IPreviewOrderProps extends RouteComponentProps<any> {
  handleCancel?: any
  loginUserData?: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
class PreviewOrderDetailsPage extends React.Component<IPreviewOrderProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      orderRequest: {},
      shipmentAddressDataState: {},
      billingAddressDataState: {},
      vendorDataState: [],
      shippedApproveState: true,
      paidApproveState: true,
      cancelButtonDisable: true,
      isLoading: false,
      orderStatusState: '',
      failureReasonState: '',
      showErrorFailureReason: false,
      showError: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isFileLinked: false,
      showShipForm: false,
      file_url: '',
      orderStatusData: [
        {
          label: 'Paid',
          value: 'PAID',
        },
        {
          label: 'Failed',
          value: 'FAILED',
        },
      ],
    }
  }

  totalPrice = 0
  totalDiscount = 0
  shipMentPrice = 0

  viewFileUploadModalRef: {show: () => void} | undefined

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
      this.setState({
        viewFileUploadModal: false,
        isFileLinked: false,
        // file_url: `${appAPIURL}order/invoice/${fileName}`,
        file_url: fileName,
      })
      Toaster({
        type: 'success',
        title: 'Order',
        description: 'Invoice Uploaded Successfully',
      })
      console.log('!!!!!!!!!!!uploadMedia')
    }
    //close modal popup
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {
    this.callInitialData()
  }

  // call initial data for Form
  public callInitialData = async () => {
    try {
      //   let shipMentList = await getShipMentPriceList()
      //   if (shipMentList.data) {
      //     this.setState({shipmentData: shipMentList.data})
      //   }
      this.setState({
        isLoading: true,
      })
      const {match} = this.props
      const itemId = match.params.id

      let stockDetails: any = await getOrderStockDetails(itemId)

      let vendorListData: any = await getVendorStockDataForOrder(itemId)

      let allVendorStatusShippedBoolean = false
      let allVendorStatusPaidBoolean = false
      let cancelStatusDisableBoolean = false

      if (vendorListData && vendorListData.data) {
        allVendorStatusShippedBoolean = vendorListData.data.every(
          (item: any) => item.status == 'SHIPPED' || item.status == 'PAID'
        )

        allVendorStatusPaidBoolean = vendorListData.data.every((item: any) => item.status == 'PAID')
        cancelStatusDisableBoolean = vendorListData.data.some(
          (item: any) => item.status == 'SHIPPED' || item.status == 'PAID'
        )
      }

      this.setState({
        orderRequest: stockDetails.data,
        shipmentData:
          stockDetails.data && stockDetails.data.shipment_price
            ? stockDetails.data.shipment_price
            : 0,
        data: stockDetails.data.buy_request_details.stock_ids,
        clientDataState: stockDetails.data.user ? stockDetails.data.user : {},
        shipmentAddressDataState: stockDetails.data.shipping_address
          ? stockDetails.data.shipping_address
          : {},
        billingAddressDataState: stockDetails.data.billing_address
          ? stockDetails.data.billing_address
          : {},
        vendorDataState: vendorListData && vendorListData.data ? vendorListData.data : [],
        shippedApproveState: allVendorStatusShippedBoolean,
        paidApproveState: allVendorStatusPaidBoolean,
        cancelButtonDisable: cancelStatusDisableBoolean,
      })
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  public calculateTotal = () => {
    const {data} = this.state
    if (data && data.length >= 1) {
      this.totalPrice = 0
      this.totalDiscount = 0
      data.map((invItem: any) => {
        this.totalPrice += invItem.stock.final_price
        this.totalDiscount += invItem.stock.discounts
      })
    }
    return returnPriceInThousandFormatWithCurrencySign(this.totalPrice)
  }

  public calculateAvgDiscount = () => {
    const {data} = this.state
    return returnPriceInThousandFormat(this.totalDiscount / data.length)
  }

  public calculateShipMent = () => {
    const {shipmentData} = this.state

    // if (shipmentData && shipmentData.length >= 1) {
    //   let getShipMentData = shipmentData.find(
    //     (shipItem: any) =>
    //       shipItem.from_number < this.totalPrice && shipItem.to_number > this.totalPrice
    //   )
    //   if (getShipMentData) {
    //     this.shipMentPrice = getShipMentData.price
    //   }
    // }
    this.shipMentPrice = shipmentData ? shipmentData : 0
    return returnPriceInThousandFormatWithCurrencySign(this.shipMentPrice)
  }

  public calculateGrandTotal = () => {
    let grandTotal = this.shipMentPrice + this.totalPrice
    return returnPriceInThousandFormatWithCurrencySign(grandTotal)
  }

  public handleEditOrderSubmit = async () => {
    try {
      if (!this.state.orderStatusState || this.state.orderStatusState == '') {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Please Select Order Status',
        })
        this.setState({
          showError: true,
        })
        return
      } else if (
        this.state.orderStatusState == 'FAILED' &&
        (!this.state.failureReasonState || this.state.failureReasonState == '')
      ) {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Please Enter Failure Reason',
        })
        this.setState({
          showErrorFailureReason: true,
        })
        return
      } else if (
        this.state.orderStatusState == 'PAID' &&
        (!this.state.file_url || this.state.file_url == '')
      ) {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Please Upload Invoice',
        })
        return
      }
      //   await editOrderStatusData(
      //     data._id,
      //     this.state.orderStatusState,
      //     this.state.failureReasonState,
      //     this.state.file_url
      //   )
      Toaster({
        type: 'success',
        title: 'Order',
        description: 'Order Status changed successFully',
      })
      this.props.handleCancel()
    } catch (e) {
      throw e
    }
  }

  public navigateToPage = (path: string) => {
    const {history} = this.props
    history.push(`${process.env.PUBLIC_URL}${path}`)
  }

  public orderStatusUpdate = async (status: string) => {
    try {
      let orderData: any = {}
      const {match} = this.props
      const itemId = match.params.id

      let rowIds = []
      rowIds.push(itemId)
      orderData.ids = rowIds

      orderData.status = status

      let orderStatusChange = await changeOrderStausDataApi(orderData)

      if (orderStatusChange && orderStatusChange.status == 200) {
        Toaster({
          type: 'success',
          title: 'Order',
          description: 'Updated Successfully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Failed To Update Status',
        })
      }
    } catch (e) {
      console.log(e, 'error')
    } finally {
      this.navigateToPage('/order/list')
      this.setState({
        visible: false,
      })
    }
  }

  public cancelOrderApi = async () => {
    try {
      let orderData: any = {}
      const {match} = this.props
      const itemId = match.params.id

      let cancelOrder = await cancelOrderDataApi(itemId)

      if (cancelOrder && cancelOrder.status == 200) {
        Toaster({
          type: 'success',
          title: 'Order',
          description: 'Cancelled Successfully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Failed To Cancel Order',
        })
      }
    } catch (e) {
      console.log(e, 'error')
    } finally {
      this.navigateToPage('/order/list')
      this.setState({
        visible: false,
      })
    }
  }

  public generateCsv = async () => {
    const {match} = this.props
    const itemId = match.params.id
    try {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          getOrderDataForCsv(itemId)
            .then((res: any) => {
              let csvArray = []

              csvArray.push([
                'COMPANY NAME',
                'STOCK ID',
                'ORDER CODE',
                'VENDOR NAME',
                'COMPANY',
                'STATUS',
                'ORDER DATE',
                'TOTAL AMOUNT',
                'DOLLAR RATE',
                'SHAPE',
                'COLOR',
                'CLARITY',
                'CUT',
                'POLISH',
                'SYM',
                'DISCOUNT',
                '$/CT',
                'FINAL PRICE',
              ])

              res.data.map((item: any) => {
                csvArray.push([
                  item.order &&
                  item.order.user_details &&
                  item.order.user_details.company_operating_name
                    ? item.order.user_details.company_operating_name
                    : 'NA',
                  item.stock && item.stock.stock_id ? item.stock.stock_id : 'NA',
                  item.order && item.order.order_code ? item.order.order_code : 'NA',
                  item.vendor && item?.vendor?.first_name && item?.vendor?.last_name
                    ? `${item.vendor.first_name} ${item.vendor.last_name}`
                    : item.vendor.first_name
                    ? item.vendor.first_name
                    : 'NA',
                  item.vendor && item?.vendor?.company_name ? item.vendor.company_name : 'NA',
                  item.status ? item.status : 'NA',
                  item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY') : 'NA',
                  item.amount ? item.amount.toFixed(2) : '0',
                  item.dollar_rate ? item.dollar_rate : '0',
                  item.stock && item.stock.shape ? returnStockId(item.stock.shape) : 'NA',
                  item.stock && item.stock.color ? returnStockId(item.stock.color) : 'NA',
                  item.stock && item.stock.clarity ? returnStockId(item.stock.clarity) : 'NA',
                  item.stock && item.stock.cut ? returnStockId(item.stock.cut) : 'NA',
                  item.stock && item.stock.polish ? returnStockId(item.stock.polish) : 'NA',
                  item.stock && item.stock.symmetry ? returnStockId(item.stock.symmetry) : 'NA',
                  item.stock && item.stock.discounts ? item.stock.discounts : 'NA',
                  item.stock && item.stock.price_per_caret ? item.stock.price_per_caret : 'NA',
                  item.stock && item.stock.final_price
                    ? returnPriceInThousandFormatWithCurrencySign(item.stock.final_price)
                    : '0',
                ])
              })

              const rows = csvArray
              let csvName = `Vendor-Stock-Details.csv`
              let csvContent = 'data:text/csv;charset=utf-8,'

              rows.forEach(function (rowArray) {
                let row = rowArray.join(',')
                csvContent += row + '\r\n'
              })

              var encodedUri = encodeURI(csvContent)
              // // // // window.open(encodedUri);
              var link = document.createElement('a')
              link.setAttribute('href', encodedUri)
              link.setAttribute('download', csvName)
              document.body.appendChild(link) // Required for FF

              link.click()

              this.setState({
                isLoading: false,
              })
            })
            .catch((err: any) => {
              console.log('ERROR', err)

              this.setState({
                isLoading: true,
              })

              throw err
            })
        }
      )
    } catch (e) {
      console.log('error in generate csv', e)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  vendorsDataCounter = 0
  vendorsDataTempCounter = 0
  public vendorsDataColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.vendorsDataTempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Company Name',
      key: 'company_operating_name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.order &&
              item.order.user_details &&
              item.order.user_details.company_operating_name
                ? item.order.user_details.company_operating_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'STOCK ID',
      key: 'stock_id',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <a
              href={`/inventory/preview/${item.stock.stock_id ? item.stock.stock_id : ''}`}
              target='_blank'
            >
              <strong>{item.stock && item.stock.stock_id ? item.stock.stock_id : '-'}</strong>
            </a>
          </div>
        )
      },
    },
    {
      title: 'Order Code',
      key: 'order_code',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.order && item.order.order_code ? item.order.order_code : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Vendor Name',
      key: 'vendor',
      sorter: (a: any, b: any) => {
        const fullNameA = `${(a.vendor && a.vendor.first_name) || ''} ${
          (a.vendor && a.vendor.last_name) || ''
        }`.trim()
        const fullNameB = `${(b.vendor && b.vendor.first_name) || ''} ${
          (b.vendor && b.vendor.last_name) || ''
        }`.trim()
        return fullNameA.localeCompare(fullNameB)
      },
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.vendor && item?.vendor?.first_name && item?.vendor?.last_name
                ? `${item.vendor.first_name} ${item.vendor.last_name}`
                : item.vendor.first_name
                ? item.vendor.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Company',
      key: 'vendor',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.vendor && item?.vendor?.company_name ? item.vendor.company_name : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Status',
      key: 'order_status',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item && item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Order Date',
      key: 'createdAt',
      sorter: (a: any, b: any) => {
        const dateA: any = new Date(a.createdAt)
        const dateB: any = new Date(b.createdAt)
        return dateA - dateB
      },
      render: (text: any, item: any, index: number) => {
        return <div>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY') : '-'}</div>
      },
    },
    {
      title: 'Total Amount',
      key: 'amount',
      render: (text: any, item: any, index: number) => {
        return <div> {item.amount ? item.amount.toFixed(2) : '0'}</div>
      },
    },
    {
      title: 'Dollar Rate',
      key: 'dollar_rate',
      render: (text: any, item: any, index: number) => {
        return <div>{item.dollar_rate ? item.dollar_rate : '0'}</div>
      },
    },
    {
      // title: 'Shape',
      title: 'SHAPE',
      key: 'shape',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.shape ? returnStockId(item.stock.shape) : '-'}</div>
      },
    },
    // {
    //   // title: 'Weight',
    //   title: 'WEIGHT',
    //   key: 'weight',
    //   width: 75,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.stock && item.stock.weight ? item.stock.weight : '-'}</div>
    //   },
    // },
    {
      // title: 'Color',
      title: 'COLOR',
      key: 'color',
      // ellipsis: true,
      // width: 40,
      width: 65,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.color ? returnStockId(item.stock.color) : '-'}</div>
      },
    },
    {
      // title: 'Clarity',
      title: 'CLARITY',
      key: 'clarity',
      width: 75,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.clarity ? returnStockId(item.stock.clarity) : '-'}</div>
        )
      },
    },
    {
      // title: 'Cut',
      title: 'CUT',
      key: 'cut',
      // width: 40,
      width: 45,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.cut ? returnStockId(item.stock.cut) : '-'}</div>
      },
    },
    {
      // title: 'Polish',
      title: 'POLISH',
      // ellipsis: true,
      key: 'polish',
      width: 70,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.polish ? returnStockId(item.stock.polish) : '-'}</div>
      },
    },
    {
      // title: 'Symmetry',
      title: 'SYM',
      // ellipsis: true,
      key: 'symmetry',
      width: 50,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.symmetry ? returnStockId(item.stock.symmetry) : '-'}</div>
        )
      },
    },
    {
      // title: 'Discounts',
      title: 'DISCOUNTS',
      // ellipsis: true,
      key: 'discounts',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return <div>{item.stock && item.stock.discounts ? item.stock.discounts : '-'}</div>
      },
    },
    {
      // title: 'Price Per Caret',
      title: '$/CT',
      // ellipsis: true,
      key: 'price_per_caret',
      width: 60,
      render: (text: any, item: any, index: number) => {
        return (
          <div>{item.stock && item.stock.price_per_caret ? item.stock.price_per_caret : '-'}</div>
        )
      },
    },
    {
      // title: 'Final Price',
      title: 'FINAL PRICE',
      // ellipsis: true,
      key: 'final_price',
      width: 100,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.stock && item.stock.final_price
              ? returnPriceInThousandFormatWithCurrencySign(item.stock.final_price)
              : '-'}
          </div>
        )
      },
    },
    // {
    //   title: 'Actions',
    //   key: 'action',
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div style={{display: 'inline-block', width: '100%'}}>
    //         <div style={{float: 'left', marginRight: '5px'}}>
    //           <Tooltip placement='bottom' title='Edit'>
    //             <Button
    //               shape='circle'
    //               icon={<EyeOutlined />}
    //               onClick={() => {
    //                 this.showPreviewModal(item)
    //                 // this.navigateToPreviewOrderPage(item)
    //               }}
    //             />
    //           </Tooltip>
    //         </div>
    //       </div>
    //     )
    //   },
    // },
  ]

  public orderShippedStatusUpdate = async (values: any) => {
    try {
      let orderData: any = {...values}
      const {match} = this.props
      const itemId = match.params.id

      let rowIds = []
      rowIds.push(itemId)
      orderData.ids = rowIds

      let orderStatusChange = await changeOrderStausDataApi(orderData)

      if (orderStatusChange && orderStatusChange.status == 200) {
        Toaster({
          type: 'success',
          title: 'Order',
          description: 'Updated Successfully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Failed To Update Status',
        })
      }
    } catch (e) {
      console.log(e, 'error')
    } finally {
      this.navigateToPage('/order/list')
      this.setState({
        visible: false,
      })
    }
  }

  showShippedFormModal = () => {
    this.setState({
      showShipForm: true,
    })
  }

  closeShippedForm = () => {
    this.setState({
      showShipForm: false,
    })
  }

  onSubmitShipForm = async (values: any) => {
    values.status = 'SHIPPED'
    await this.orderShippedStatusUpdate(values)
    await this.closeShippedForm()
  }

  public render() {
    const {loginUserData} = this.props
    const {
      data,
      orderRequest,
      billingAddressDataState,
      shipmentAddressDataState,
      isLoading,
      stockDetails,
    } = this.state

    return (
      <div className='card card-custom'>
        <Spin spinning={isLoading} tip='Loading...'>
          {/* <div className='alert alert-primary text-center font-weight-bold' role='alert'>
            {'Please check your mail for further process'}
          </div> */}
          <div className='card-body'>
            <div>
              <div className='mb-6' style={{position: 'relative'}}>
                <h3 className='font-weight-bold' style={{marginBottom: '0', lineHeight: '150%'}}>
                  {`${orderRequest.order_code && returnStockId(orderRequest.order_code)}`}
                </h3>
                <span style={{fontWeight: 500}}>
                  {orderRequest &&
                  orderRequest?.user_details &&
                  orderRequest?.user_details?.business_entity_name
                    ? orderRequest.user_details.business_entity_name
                    : '-'}
                  {' | '}
                  {orderRequest &&
                  orderRequest?.user_details &&
                  orderRequest?.user_details?.first_name &&
                  orderRequest?.user_details?.last_name
                    ? `${orderRequest.user_details.first_name} ${orderRequest.user_details.last_name}`
                    : orderRequest?.user_details?.first_name
                    ? orderRequest?.user_details?.first_name
                    : '-'}
                  {' | '}
                  {orderRequest && orderRequest?.user_details && orderRequest?.user_details?.email
                    ? orderRequest.user_details.email
                    : '-'}
                </span>
                <div
                  className='right-side-details'
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  }}
                >
                  <div>
                    {' '}
                    <h3
                      className='font-weight-bold text-right'
                      style={{marginBottom: '0', lineHeight: '150%'}}
                    >
                      {orderRequest?.order_status && (
                        <Tag
                          className='p-2'
                          color={
                            config.statusTagColor[orderRequest.order_status]
                              ? config.statusTagColor[orderRequest.order_status]
                              : 'default'
                          }
                        >
                          {orderRequest.order_status}
                        </Tag>
                      )}
                    </h3>
                  </div>
                  <span
                    className='font-weight-bold'
                    style={{marginTop: '10px', paddingRight: '8px', fontWeight: 500}}
                  >
                    Date :{' '}
                    {orderRequest.createdAt
                      ? moment(orderRequest.createdAt).format('DD-MM-YYYY')
                      : '-'}
                  </span>
                </div>
              </div>
              {/* <div className='row mb-10'>
                <div className='col-md-6 col-lg-6 col-sm-12'>
                  <h3>
                    {'Order Details'}
                    {` (${orderRequest.order_code && returnStockId(orderRequest.order_code)})`}
                  </h3>
                </div>
                <div className='col-md-3 col-lg-3 col-sm-12'>
                  <strong>
                    {orderRequest?.order_status && (
                      <Tag
                        className='p-2'
                        color={
                          config.statusTagColor[orderRequest.order_status]
                            ? config.statusTagColor[orderRequest.order_status]
                            : 'default'
                        }
                      >
                        {orderRequest.order_status}
                      </Tag>
                    )}
                  </strong>
                </div>
                <div className='col-md-3 col-lg-3 col-sm-12'>
                  <strong>
                    {orderRequest.createdAt
                      ? moment(orderRequest.createdAt).format('DD-MM-YYYY')
                      : '-'}
                  </strong>
                </div>
              </div>
              <div className='form-group'>
                <div className='row'>
                  <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                    <label>{'Order Code:'}</label>
                  </div>
                  <div className='col-md-4 col-lg-4 col-sm-12'>
                    <label>
                      {orderRequest.order_code ? returnStockId(orderRequest.order_code) : '-'}
                    </label>
                  </div>

                  <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                    <label>{'Order Date:'}</label>
                  </div>
                  <div className='col-md-4 col-lg-4 col-sm-12'>
                    <label>
                      {orderRequest.createdAt
                        ? moment(orderRequest.createdAt).format('DD-MM-YYYY')
                        : '-'}
                    </label>
                  </div>
                </div>
                <>
                  <div className='row'>
                    <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                      <label>{'Name:'}</label>
                    </div>
                    <div className='col-lg-4'>
                      <label>
                        {orderRequest &&
                        orderRequest?.user_details &&
                        orderRequest?.user_details?.first_name &&
                        orderRequest?.user_details?.last_name
                          ? `${orderRequest.user_details.first_name} ${orderRequest.user_details.last_name}`
                          : orderRequest?.user_details?.first_name
                          ? orderRequest?.user_details?.first_name
                          : '-'}
                      </label>
                    </div>
                    <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                      <label>{'Company:'}</label>
                    </div>
                    <div className='col-lg-4'>
                      <label>
                        {orderRequest &&
                        orderRequest?.user_details &&
                        orderRequest?.user_details?.business_entity_name
                          ? orderRequest.user_details.business_entity_name
                          : '-'}
                      </label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                      <label>{'Email:'}</label>
                    </div>
                    <div className='col-lg-4'>
                      <label>
                        {orderRequest &&
                        orderRequest?.user_details &&
                        orderRequest?.user_details?.email
                          ? orderRequest.user_details.email
                          : '-'}
                      </label>
                    </div>
                  </div>
                </>
              </div> */}
              <div className='row no-gutters pt-4' style={{borderTop: '1px solid #eff2f5'}}>
                <div className='col-lg-6 col-md-6 col-sm-12 mb-4 ml-2 text-muted'>
                  <h3 className='mb-2'>{'Billing Address'}</h3>
                  <span>
                    {billingAddressDataState && (
                      <span className='text-dark font-weight-normal' style={{fontSize: '14px'}}>
                        {billingAddressDataState.address_line_one
                          ? ` ${billingAddressDataState.address_line_one},`
                          : ''}
                        {billingAddressDataState.address_line_two
                          ? ` ${billingAddressDataState.address_line_two},`
                          : ''}
                        <br />
                        {billingAddressDataState.city ? ` ${billingAddressDataState.city},` : ''}
                        {billingAddressDataState.state ? ` ${billingAddressDataState.state},` : ''}
                        <br />
                        {billingAddressDataState.country
                          ? ` ${billingAddressDataState.country} -`
                          : ''}
                        {billingAddressDataState.zip_code
                          ? ` ${billingAddressDataState.zip_code}`
                          : ''}
                      </span>
                    )}
                  </span>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-12 mb-4 pr-5'>
                  <h3 className='mb-2'>{'Shipping Address'}</h3>
                  <span>
                    {shipmentAddressDataState && (
                      <div className='text-dark font-weight-normal' style={{fontSize: '14px'}}>
                        {shipmentAddressDataState.address_line_one
                          ? ` ${shipmentAddressDataState.address_line_one},`
                          : ''}
                        {shipmentAddressDataState.address_line_two
                          ? ` ${shipmentAddressDataState.address_line_two},`
                          : ''}
                        {/* {shipmentAddressDataState.street
                              ? ` ${shipmentAddressDataState.street},`
                              : ''} */}
                        <br />
                        {shipmentAddressDataState.city ? ` ${shipmentAddressDataState.city},` : ''}
                        {shipmentAddressDataState.state
                          ? ` ${shipmentAddressDataState.state},`
                          : ''}
                        <br />
                        {shipmentAddressDataState.country
                          ? ` ${shipmentAddressDataState.country} -`
                          : ''}
                        {shipmentAddressDataState.zip_code
                          ? ` ${shipmentAddressDataState.zip_code}`
                          : ''}
                      </div>
                    )}
                  </span>
                </div>
              </div>

              {orderRequest && orderRequest.order_status !== 'PENDING' && (
                <div className='row no-gutters pt-4' style={{borderTop: '1px solid #eff2f5'}}>
                  <div className='col-lg-6 col-md-6 col-sm-12 mb-4 ml-2 text-muted'>
                    <h3
                      className='font-weight-bold mb-2'
                      style={{
                        marginTop: '10px',
                        paddingRight: '8px',
                        fontWeight: 500,
                        fontSize: '14px',
                      }}
                    >
                      {'AWB Number :  '}{' '}
                      <span
                        className='font-weight-bold'
                        style={{fontSize: '13px', color: 'rgba(0, 0, 0, 0.65)'}}
                      >
                        {orderRequest && orderRequest.awb_number ? orderRequest.awb_number : '-'}
                      </span>
                    </h3>
                  </div>
                  <div className='col-lg-5 col-md-5 col-sm-12 mb-4 pr-5'>
                    <h3
                      className='font-weight-bold mb-2'
                      style={{
                        marginTop: '10px',
                        paddingRight: '8px',
                        fontWeight: 500,
                        fontSize: '14px',
                      }}
                    >
                      {'Courier Company :  '}{' '}
                      <span
                        className='font-weight-bold'
                        style={{fontSize: '13px', color: 'rgba(0, 0, 0, 0.65)'}}
                      >
                        {orderRequest && orderRequest.courier_company
                          ? orderRequest.courier_company
                          : '-'}
                      </span>
                    </h3>
                  </div>
                </div>
              )}

              <div className='row border-bottom pt-4' style={{borderTop: '1px solid #eff2f5'}}>
                <h3 className='mb-10'>{'Stock Details'}</h3>
                <div className='col-lg-1 text-dark font-weight-bold text-muted'>{'Stock Id'}</div>
                <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                  {'Vendor Name'}
                </div>
                <div className='col-lg-5 text-dark font-weight-bold text-muted'>{'Details'}</div>
                <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                  <div className='text-right'>
                    <label>{'Discount'}</label>
                  </div>
                </div>
                <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                  <div className='text-right'>
                    <label>{'$/Ct'}</label>
                  </div>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                  <div className='text-right'>
                    <label>{'Price'}</label>
                  </div>
                </div>
              </div>
              <div style={{maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto'}}>
                {data &&
                  !!data.length &&
                  data.map(
                    (invItem: any, index: number) =>
                      invItem && (
                        <div className='row mt-10' key={index}>
                          <div className='col-lg-1 text-dark font-weight-bold'>
                            <label>
                              <a
                                href={`/inventory/preview/${
                                  invItem.stock && invItem.stock.stock_id
                                    ? invItem.stock.stock_id
                                    : ''
                                }`}
                                target='_blank'
                              >
                                {invItem && invItem.stock && invItem.stock.stock_id
                                  ? returnStockId(invItem.stock.stock_id)
                                  : '-'}
                              </a>
                            </label>
                          </div>
                          <div className='col-lg-2 text-dark font-weight-bold'>
                            <div className='text-left'>
                              <label>
                                {invItem && invItem.vendor
                                  ? invItem.vendor.first_name && invItem.vendor.last_name
                                    ? `${invItem.vendor.first_name} ${invItem.vendor.last_name}`
                                    : invItem.vendor.first_name
                                    ? invItem.vendor.first_name
                                    : '-'
                                  : '-'}
                              </label>
                            </div>
                          </div>
                          <div className='col-lg-5 text-dark font-weight-bold'>
                            <label>
                              {invItem && invItem.stock ? returnStockString(invItem.stock) : '-'}
                            </label>
                          </div>
                          <div className='col-lg-1 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>
                                {invItem && invItem.stock && invItem.stock.discounts
                                  ? invItem.stock.discounts
                                  : '-'}
                              </label>
                            </div>
                          </div>
                          <div className='col-lg-1 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>
                                {invItem && invItem.stock && invItem.stock.price_per_caret
                                  ? invItem.stock.price_per_caret
                                  : '-'}
                              </label>
                            </div>
                          </div>
                          <div className='col-lg-2 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>
                                {invItem && invItem.stock && invItem.stock.final_price
                                  ? returnPriceInThousandFormatWithCurrencySign(
                                      invItem.stock.final_price
                                    )
                                  : '-'}
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </div>
              <div className='row border-top mt-10'>
                <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                  <label>{'Sub Total:'}</label>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold'>
                  <div className='text-right'>
                    <label>{this.calculateTotal()} </label>
                  </div>
                </div>
              </div>
              {/* <div className='row'>
                <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                  <label>{'Discount:'}</label>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold'>
                  <div className='text-right'>
                    <label>{`${this.calculateAvgDiscount()}(%)`} </label>
                  </div>
                </div>
              </div> */}
              <div className='row'>
                <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                  <label>{'Shipment Price:'}</label>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold'>
                  <div className='text-right'>
                    <label>{this.calculateShipMent()} </label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                  {'Tax Price:'}
                </div>
                <div className='col-lg-2 text-dark font-weight-bold'>
                  <div className='text-right'>
                    {orderRequest && orderRequest.tax_price
                      ? returnPriceInThousandFormatWithCurrencySign(orderRequest.tax_price)
                      : '0'}
                  </div>
                </div>
              </div>
              <div className='row border-bottom'>
                <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                  <label>{'Total:'}</label>
                </div>
                <div className='col-lg-2 text-dark font-weight-bold'>
                  <div className='text-right'>
                    {/* <label>{this.calculateGrandTotal()} </label> */}
                    <label>
                      {orderRequest && orderRequest.grand_total
                        ? returnPriceInThousandFormatWithCurrencySign(orderRequest.grand_total)
                        : '0'}{' '}
                    </label>
                  </div>
                </div>
              </div>

              <div className='pt-4' style={{marginBottom: '30px'}}>
                <div className='col-lg-6 col-md-12 col-sm-12' style={{padding: 0}}>
                  <div style={{fontSize: '20px', fontWeight: 500}}>Vendor Stocks Details : </div>
                </div>
              </div>
              {this.state.vendorDataState ? (
                <>
                  <div style={{overflowX: 'auto'}}>
                    <div className='card-toolbar d-flex justify-content-end mb-4'>
                      <div className='ms-3'>
                        <InputButtonComponent
                          onClick={() => {
                            this.generateCsv()
                          }}
                        >
                          Export Vendor Stock Details
                        </InputButtonComponent>
                      </div>
                    </div>
                    <ListTable
                      rowKey={(record: {_id: any}) => record._id}
                      onChange={() => {}}
                      onPaginationChange={(e: any) => {}}
                      column={this.vendorsDataColumns}
                      paginationConfig={{
                        total: this.state.vendorDataState.length,
                        showSizeChanger: false,
                        pageSize: 10000,
                        current: 1,
                      }}
                      isLoading={false}
                      dataSource={this.state.vendorDataState}
                      counter={this.vendorsDataCounter}
                      tempCounter={this.vendorsDataTempCounter}
                      isScroll={true}
                      scrollWidth={1600}
                    />
                  </div>
                </>
              ) : (
                <div>No Vendor Stocks</div>
              )}

              <div className='card-footer' style={{marginBottom: '40px', paddingRight: '0px'}}>
                <>
                  {console.log(
                    this.state.paidApproveState,
                    this.state.shippedApproveState,
                    'Status!!!!'
                  )}
                  {orderRequest.order_status == 'SHIPPED' && (
                    <Popconfirm
                      title='Are you sure you want to change order status as completed?'
                      placement='topLeft'
                      okText='Complete'
                      onConfirm={() => this.orderStatusUpdate('DELIVERED')}
                      disabled={!this.state.paidApproveState}
                      // icon={<FontAwesomeIcon icon={faTruck} style={{color: "#1aff1d",display:"inline-block"}} />}
                    >
                      <div className='float-right rounded'>
                        <button
                          type='button'
                          className='btn btn-sm'
                          style={{
                            cursor: `${!this.state.paidApproveState ? 'not-allowed' : 'pointer'}`,
                            border: 'none',
                            color: 'white',
                            backgroundColor: `${
                              !this.state.paidApproveState ? '#6c757d' : '#007bff'
                            }`,
                          }}
                        >
                          {'Complete Order'}
                        </button>
                      </div>
                    </Popconfirm>
                  )}
                  {orderRequest.order_status == 'PENDING' && (
                    <Popconfirm
                      title='Are you sure you want to change order status as shipped?'
                      placement='topLeft'
                      okText='Ship'
                      disabled={!this.state.shippedApproveState}
                      // onConfirm={() => this.orderStatusUpdate('SHIPPED')}
                      onConfirm={() => this.showShippedFormModal()}
                      // icon={<FontAwesomeIcon icon={faTruck} style={{color: "#1aff1d",display:"inline-block"}} />}
                    >
                      <div className='float-right rounded'>
                        <button
                          type='button'
                          className='btn btn-sm'
                          style={{
                            cursor: `${
                              !this.state.shippedApproveState ? 'not-allowed' : 'pointer'
                            }`,
                            border: 'none',
                            color: 'white',
                            backgroundColor: `${
                              !this.state.shippedApproveState ? '#6c757d' : '#007bff'
                            }`,
                          }}
                        >
                          {'Ship Order'}
                        </button>
                      </div>
                    </Popconfirm>
                  )}
                  {orderRequest.order_status == 'PENDING' && (
                    <Popconfirm
                      title='Are you sure you want to cancel order?'
                      placement='topLeft'
                      okText='Cancel'
                      disabled={this.state.cancelButtonDisable}
                      onConfirm={() => this.cancelOrderApi()}
                      // icon={<FontAwesomeIcon icon={faTruck} style={{color: "#1aff1d",display:"inline-block"}} />}
                    >
                      <div className='float-right rounded mr-4'>
                        <button
                          type='button'
                          className='btn btn-sm'
                          style={{
                            cursor: `${this.state.cancelButtonDisable ? 'not-allowed' : 'pointer'}`,
                            border: 'none',
                            color: 'white',
                            backgroundColor: `${
                              this.state.cancelButtonDisable ? '#6c757d' : '#f5222d'
                            }`,
                          }}
                        >
                          {'Cancel Order'}
                        </button>
                      </div>
                    </Popconfirm>
                  )}
                  <div className='float-right rounded mr-4'>
                    <button
                      type='button'
                      className='btn btn-sm'
                      onClick={() => this.navigateToPage('/order/list')}
                      style={{
                        cursor: 'pointer',
                        border: 'none',
                        backgroundColor: '#8B93FF',
                        color: 'white',
                      }}
                    >
                      {'Back'}
                    </button>
                  </div>
                </>
                <CommanModal
                  show={this.state.showShipForm ? true : false}
                  handleClose={() => {
                    this.closeShippedForm()
                  }}
                  title={'Enter Order Shipped Details'}
                  submitText={'Save Changes'}
                >
                  <div>
                    {this.state.showShipForm && (
                      <ShipOrderForm
                        onSubmit={this.onSubmitShipForm}
                        handleCancel={() => {
                          this.closeShippedForm()
                        }}
                      />
                    )}
                  </div>
                </CommanModal>
              </div>
              {/* {data?.status == 'PENDING' && (
                  <>
                    <div className='row mt-3 mb-3'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <h4>{'Order Status'}</h4>
                      </div>
                    </div>
                    <div className='row mt-3 mb-3'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputSingleSelect
                          input={{
                            value: this.state.orderStatusState,
                            id: 'order_status',
                            name: 'order_status',
                            options: this.state.orderStatusData,
                          }}
                          placeholder='Select Order Status'
                          onChangeMethod={(value: any) => {
                            this.setState({
                              orderStatusState: value,
                            })
                            this.setState({
                              showError: false,
                            })
                          }}
                          label='Select Order Status'
                          error={() => {}}
                          touched={() => {}}
                        />
                        {this.state.showError && (
                          <p style={{color: 'red', marginTop: '10px', marginBottom: '10px'}}>
                            Please Select Order Status
                          </p>
                        )}
                      </div>
                      {this.state.orderStatusState == 'FAILED' && (
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: this.state.failureReasonState,
                              id: 'failure_reason',
                              name: 'failure_reason',
                            }}
                            placeholder='Enter Failure Reason'
                            onChange={(value: string) => {
                              this.setState({
                                failureReasonState: value,
                              })
                            }}
                            onBlur={() => {}}
                            label='Failure Reason'
                            error={() => {}}
                            touched={() => {}}
                          />
                          {this.state.showErrorFailureReason && (
                            <p style={{color: 'red', marginTop: '10px', marginBottom: '10px'}}>
                              Please Enter Failure Reason
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {data.failure_reason ? (
                  <div style={{marginBottom: '10px'}}>
                    <div style={{margin: '10', alignItems: 'center'}}>
                      <Alert message={data.failure_reason} type='error' showIcon />
                    </div>
                  </div>
                ) : null}
                {data.attached_file_url ? (
                  <div>
                    {data.attached_file_url &&
                      data.attached_file_url.split('.')[
                        data.attached_file_url.split('.').length - 1
                      ] === 'pdf' && (
                        <div style={{margin: '10', alignItems: 'center'}}>
                          <Alert message='Click To Download Attachment' type='success' showIcon />
                          <Button>
                            <a href={data.attached_file_url} target='_blank'>
                              Click to Download
                            </a>
                          </Button>
                        </div>
                      )}
                    {((data.attached_file_url &&
                      data.attached_file_url.split('.')[
                        data.attached_file_url.split('.').length - 1
                      ] === 'jpg') ||
                      (data.attached_file_url &&
                        data.attached_file_url.split('.')[
                          data.attached_file_url.split('.').length - 1
                        ] === 'jpeg') ||
                      (data.attached_file_url &&
                        data.attached_file_url.split('.')[
                          data.attached_file_url.split('.').length - 1
                        ] === 'png')) && (
                      <img
                        src={data.attached_file_url}
                        alt='Logo'
                        style={{width: '350px', height: '275px', margin: '20px auto'}}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    style={{marginTop: '10px'}}
                    className='alert alert-danger text-center font-weight-bold'
                    role='alert'
                  >
                    NO ATTACHMENTS FOUND
                  </div>
                )}

                {data.invoice_file_url ? (
                  <div style={{marginTop: '10px'}}>
                    {data.invoice_file_url &&
                      data.invoice_file_url.split('.')[
                        data.invoice_file_url.split('.').length - 1
                      ] === 'pdf' && (
                        <div style={{margin: '10', alignItems: 'center'}}>
                          <Alert message='Click To Download Invoice' type='success' showIcon />
                          <Button>
                            <a href={data.invoice_file_url} target='_blank'>
                              Click to Download
                            </a>
                          </Button>
                        </div>
                      )}
                    {console.log(
                      data.invoice_file_url &&
                        data.invoice_file_url.split('.')[
                          data.invoice_file_url.split('.').length - 1
                        ]
                    )}
                    {((data.invoice_file_url &&
                      data.invoice_file_url.split('.')[
                        data.invoice_file_url.split('.').length - 1
                      ] === 'jpg') ||
                      (data.invoice_file_url &&
                        data.invoice_file_url.split('.')[
                          data.invoice_file_url.split('.').length - 1
                        ] === 'jpeg') ||
                      (data.invoice_file_url &&
                        data.invoice_file_url.split('.')[
                          data.invoice_file_url.split('.').length - 1
                        ] === 'png')) && (
                      <img
                        src={data.invoice_file_url}
                        alt='Logo'
                        style={{width: '350px', height: '275px', margin: '20px auto'}}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    style={{marginTop: '10px'}}
                    className='alert alert-danger text-center font-weight-bold'
                    role='alert'
                  >
                    NO ATTACHMENTS FOR INVOICE FOUND
                  </div>
                )} */}
              {/* {this.state.orderStatusState == 'PAID' && loginUserData?.role === 'admin' && (
                  <>
                    <div className='row mt-3 mb-3'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <h4>{'Upload Invoice'}</h4>
                      </div>
                    </div>
                    <div style={{marginTop: '15px', marginBottom: '15px'}}>
                      <InputSingleFileUpload
                        ref={(e: any) => {
                          this.viewFileUploadModalRef = e
                        }}
                        onBlur={() => {}}
                        error={() => {}}
                        touched={() => {}}
                        name='file'
                        input={{
                          id: 'invoice',
                          name: 'invoice',
                          action: `${appAPIURL}file/upload/order/invoice`,
                          headers: {
                            authorization: 'authorized-text',
                          },
                          accept: 'pdf|png|jpeg|jpg|gif|webp',
                          acceptMimeTypes: [
                            'image/png',
                            'image/jpeg',
                            'image/jpg',
                            'application/pdf',
                          ],
                          mediaType: 'pdf|png|jpeg|jpg|gif|webp',
                          mediaModuleType: 'pdf|png|jpeg|jpg|gif|webp',
                        }}
                        onUpload={(
                          fileName: string,
                          FileData: Array<any>,
                          customFileData: Object
                        ) => {
                          this.uploadMedia(fileName, FileData, customFileData)
                        }}
                      />
                    </div>
                    {this.state.file_url ? (
                      // <img
                      //   src={`${this.state.file_url}`}
                      //   alt='Invoice'
                      //   style={{width: '350px', height: '75px', margin: '10px auto'}}
                      // />
                      <div>
                        {this.state.file_url &&
                          this.state.file_url.split('.')[
                            this.state.file_url.split('.').length - 1
                          ] === 'pdf' && (
                            <div style={{margin: '10%', alignItems: 'center'}}>
                              <Alert message='Click To Download Pdf' type='success' showIcon />
                              <Button style={{marginTop: '10px'}}>
                                <a href={this.state.file_url} target='_blank'>
                                  Click to Download Pdf
                                </a>
                              </Button>
                            </div>
                          )}
                        {console.log(
                          this.state.file_url &&
                            this.state.file_url.split('.')[
                              this.state.file_url.split('.').length - 1
                            ]
                        )}
                        {((this.state.file_url &&
                          this.state.file_url.split('.')[
                            this.state.file_url.split('.').length - 1
                          ] === 'jpg') ||
                          (this.state.file_url &&
                            this.state.file_url.split('.')[
                              this.state.file_url.split('.').length - 1
                            ] === 'jpeg') ||
                          (this.state.file_url &&
                            this.state.file_url.split('.')[
                              this.state.file_url.split('.').length - 1
                            ] === 'png')) && (
                          <img
                            src={this.state.file_url}
                            alt='Logo'
                            style={{width: '350px', height: '275px', margin: '20px auto'}}
                          />
                        )}
                      </div>
                    ) : null}
                  </>
                )}
                {data?.status == 'PENDING' && loginUserData?.role === 'admin' && (
                  <div>
                    <div className='float-right'>
                      <InputButtonComponent onClick={this.handleEditOrderSubmit}>
                        Save
                      </InputButtonComponent>
                    </div>
                  </div>
                )} */}
              {/* <div className='row border-bottom'>
                  <div className='col-lg-6 font-weight-bold text-muted'>{'Stock Id'}</div>
                  <div className='col-lg-6 font-weight-bold text-muted'>
                    <div className='w-25 text-right'>{'Price'}</div>
                  </div>
                </div> */}
              {/* {data.buy_request_id && data.buy_request_id.stock_diamonds ? (
                  <div className='overflow-auto' style={{maxHeight: '300px'}}>
                    {data.buy_request_id.stock_diamonds.map((stockItem: any, index: number) => (
                      <div className='row mt-10' key={index}>
                        <div className='col-lg-6 text-dark font-weight-bold'>
                          <label>
                            <a
                              href={`/inventory/preview/${stockItem.stock_id._id}`}
                              target='_blank'
                            >
                              {returnStockId(stockItem.stock_id.stock_id)}
                            </a>
                          </label>
                        </div>
                        <div className='col-lg-6 text-dark font-weight-bold'>
                          <div className='w-25 text-right'>
                            <label>
                              {(Math.round(stockItem.stock_id.final_price * 100) / 100).toFixed(2)}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  '-'
                )}{' '} */}
            </div>
          </div>
        </Spin>
      </div>
    )
  }
}

export default withRouter(PreviewOrderDetailsPage)
