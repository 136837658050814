import * as React from 'react'
import {Formik} from 'formik'
import {InputNumberText} from '../../modules/comman/formComponents/InputNumberText'
import {Toaster} from '../../modules/comman/components/ToasterNoti'
import {Alert} from 'antd'
import {Button} from 'antd'
import {ListTable} from '../../modules/comman/ListComponenets/ListTable'
import {Popconfirm} from 'antd'
import {InputButtonComponent} from '../../modules/comman/formComponents/InputButtonComponent'
import {CommanModal} from '../../modules/comman/components/ModalComponent'
import {config} from '../../comman/constants'
import {verifyKycStatus} from '../kycVendor/redux/KycCRUD'
import {RejectReasonForm} from '../kyc/containers/RejectReasonForm'

const PRIMARY_COLOR = config.buttonPrimaryColor

interface IRenderFormikForm {
  values: IBannerFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

// export interface IVendorFormValues {
//   margin_percentage?: number
// }

export interface IBannerFormValues {
  // credit_limit: number
  id: string
  is_verified: boolean
  margin_percentage?: number
}

interface IKycProps {
  data?: any
  handleCancel?: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export class KycRejectedView extends React.Component<IKycProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isImageLinked: false,
      image_url: '',
      file_name: '',
      kycReasonModal: null,
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: IBannerFormValues) => {
    const errors: any = {}

    // if (!values.credit_limit && values.credit_limit !== 0) {
    //   errors.credit_limit = 'Credit Limit is Required'
    // }
    if (values.margin_percentage && values.margin_percentage > 100) {
      errors.margin_percentage = 'Margin must be less than 100'
    }
    if (values.margin_percentage && values.margin_percentage < 0) {
      errors.margin_percentage = 'Margin must be Positive'
    }
    return errors
  }

  public handleSubmit = async (values: IBannerFormValues, action: any, isVerified: boolean) => {
    try {
      values.is_verified = isVerified
      if (values.margin_percentage)
        values.margin_percentage = parseInt(values.margin_percentage.toString())
      else values.margin_percentage = 0
      let kycData = await verifyKycStatus(values)
      if (kycData && kycData.status == 200) {
        Toaster({
          type: 'success',
          title: 'KYC',
          description: 'KYC Status Changed Successfully',
        })
      }
    } catch (e) {
      console.log(e, '!!!!error in api call!!!!')
      Toaster({
        type: 'error',
        title: 'KYC',
        description: 'Something Went Wrong',
      })
      throw e
    } finally {
      this.props.handleCancel()
      this.setState({
        visible: false,
      })
    }
  }

  public showReasonModalModal = (item: any) => {
    this.setState({
      kycReasonModal: item,
    })
  }

  public closeReasonModal = () => {
    this.setState({
      kycReasonModal: null,
    })
  }

  public handleDeclineSubmit = async (values: IBannerFormValues, action: any) => {
    try {
      let kycData = await verifyKycStatus(values)
      if (kycData && kycData.status == 200) {
        Toaster({
          type: 'success',
          title: 'KYC',
          description: 'KYC Status Changed Successfully',
        })
      }
    } catch (e) {
      console.log(e, '!!!!error in api call!!!!')
      Toaster({
        type: 'error',
        title: 'KYC',
        description: 'Something Went Wrong',
      })
      throw e
    } finally {
      this.props.handleCancel()
      this.setState({
        visible: false,
      })
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  openInNewTab(url: any) {
    window.open(url, '_blank', 'noreferrer')
  }

  getExtension(filename: any) {
    if ((filename && filename.split('.'), filename.split('.').pop())) {
      return filename.split('.').pop()
    }
    return ''
  }

  trade_ref_counter = 0
  trade_ref_tempCounter = 0
  public tradecolumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.trade_ref_tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Company Name',
      key: 'company_name',
      render: (text: any, item: any, index: number) => {
        return <div>{item.company_name ? item.company_name : '-'}</div>
      },
    },
    {
      title: 'Contact Person Name',
      key: 'contact_person_name',
      render: (text: any, item: any, index: number) => {
        return <div>{item.contact_person_name ? item.contact_person_name : '-'}</div>
      },
    },
    {
      title: 'Email',
      key: 'email',
      render: (text: any, item: any, index: number) => {
        return <div>{item.email ? item.email : '-'}</div>
      },
    },
    {
      title: 'Phone',
      key: 'phone',
      render: (text: any, item: any, index: number) => {
        return <div>{item.phone ? item.phone : '-'}</div>
      },
    },
  ]

  owners_counter = 0
  owners_tempCounter = 0
  public ownerscolumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.owners_tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Name',
      key: 'first_name',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.first_name && item.last_name
                ? `${item.first_name} ${item.last_name}`
                : item.first_name
                ? item.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Mobile No.',
      key: 'mobile_no',
      render: (text: any, item: any, index: number) => {
        return <div>{item.mobile_no ? item.mobile_no : '-'}</div>
      },
    },
    {
      title: 'Designation',
      key: 'designation',
      render: (text: any, item: any, index: number) => {
        return <div>{item.designation ? item.designation : '-'}</div>
      },
    },
  ]

  public render() {
    const {kycReasonModal} = this.state
    console.log({pp: this.props})
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  // credit_limit: 0,
                  id: this.props.data && this.props.data.id ? this.props.data.id : '',
                  is_verified: false,
                  margin_percentage:
                    this.props.data && this.props.data.margin_percentage
                      ? this.props.data.margin_percentage
                      : 0,
                }}
                validate={this.validateForm}
                onSubmit={(values, actions) => {
                  /* Placeholder for handleSubmit, real handling in buttons */
                  console.log(values, actions)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.credit_limit,
                              id: 'credit_limit',
                              name: 'credit_limit',
                            }}
                            placeholder='Enter Credit Limit'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('credit_limit', value)
                            }}
                            onBlur={handleBlur}
                            label='Credit Limit (in Dollars)'
                            error={errors}
                            touched={touched}
                          />
                        </div> */}
                        {/* <div className='d-flex flex-column col-md-6 col-lg-6 col-sm-12 px-6'>
                          <span style={{fontSize: '14px', fontWeight: 500}}>Is Verified</span>
                          <Switch
                            defaultChecked={false}
                            checked={values.is_verified}
                            style={{
                              backgroundColor: values.is_verified ? PRIMARY_COLOR : '#bababa',
                              marginTop: '15px',
                              width: '45px',
                            }}
                            // className='align-self-end'
                            onChange={(value: any) => {
                              setFieldValue('is_verified', value)
                            }}
                          />
                        </div> */}
                      </div>
                      <div className='card-header' style={{padding: 0, marginBottom: '20px'}}>
                        <h3 className='card-title'>KYC DOCUMENTS </h3>
                      </div>
                      <div className='bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md shadow-md'>
                        <h5 className='text-lg font-semibold'>KYC Document Rejected</h5>
                        <p className='mt-1 text-sm'>
                          Your KYC document has been rejected. Please review the requirements and
                          submit your document again.
                        </p>
                      </div>

                      <div className='form-group row'>
                        {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                          <div style={{marginBottom: '15px', fontWeight: 500}}>Signature</div>
                          {this.props.data && this.props.data.signature_image_url ? (
                            <img
                              src={`${this.props.data.signature_image_url}`}
                              alt='Signature'
                              style={{maxWidth: '8rem', aspectRatio: '2:3'}}
                              onClick={() => this.openInNewTab(this.props.data.signature_image_url)}
                            />
                          ) : null}
                        </div> */}
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <div style={{marginBottom: '15px', fontWeight: 500}}>Document</div>
                          {this.props.data && this.props.data.document_url ? (
                            <>
                              {this.getExtension(this.props.data.document_url).toLowerCase() ===
                                'pdf' ||
                              this.getExtension(this.props.data.document_url).toLowerCase() ===
                                'xlsx' ? (
                                <>
                                  <Alert
                                    message={`${this.getExtension(
                                      this.props.data.document_url
                                    ).toUpperCase()} Attachment`}
                                    // description={`${this.props.data.document_url}`}
                                    type='success'
                                    showIcon
                                    style={{width: '100%'}}
                                  />
                                  <div
                                    onClick={() =>
                                      this.openInNewTab(`${this.props.data.document_url}`)
                                    }
                                    style={{cursor: 'pointer', marginTop: '10px'}}
                                  >
                                    {/* <div
                                      style={{
                                        width: '100%',
                                        backgroundColor: '#4466f2',
                                        color: 'white',
                                        padding: '6px 6px',
                                        textAlign: 'center',
                                        borderRadius: '6px',
                                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                      }}
                                    >
                                      Download 
                                    </div> */}
                                    <Button style={{width: '100%'}}>Download</Button>
                                  </div>
                                </>
                              ) : this.getExtension(this.props.data.document_url).toLowerCase() ===
                                  'png' ||
                                this.getExtension(this.props.data.document_url).toLowerCase() ===
                                  'jpg' ||
                                this.getExtension(this.props.data.document_url).toLowerCase() ===
                                  'gif' ||
                                this.getExtension(this.props.data.document_url).toLowerCase() ===
                                  'jpeg' ? (
                                <div
                                  className='col-md-6 col-lg-6 col-sm-12'
                                  style={{margin: '15px'}}
                                >
                                  <img
                                    src={`${this.props.data.document_url}`}
                                    alt='Kyc Document'
                                    style={{maxWidth: '8rem', aspectRatio: '2:3'}}
                                    onClick={() => this.openInNewTab(this.props.data.document_url)}
                                  />
                                </div>
                              ) : null}
                            </>
                          ) : null}
                          <div style={{marginBottom: '15px'}}></div>
                        </div>
                        <div className='bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded-md shadow-md'>
                          <p className='mt-1 text-sm text-gray-600'>
                            Please click on the image for a better view.
                          </p>
                        </div>
                        <div className='bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md shadow-md'>
                          <h5 className='text-lg font-semibold'>Rejected Reason :</h5>
                          <p className='mt-1 text-sm'>{this.props.data.kyc_reject_reason}</p>
                        </div>
                      </div>
                      {/* <div style={{marginBottom: '30px'}}>
                        <div className='col-lg-6 col-md-12 col-sm-12' style={{padding: 0}}>
                          <div style={{fontSize: '20px', fontWeight: 500}}>
                            References Details :{' '}
                          </div>
                        </div>
                      </div> */}
                      {this.props.data?.trade_references ? (
                        // <>
                        //   {this.props.data.trade_references.map((item: any, index: number) => (
                        //     <>
                        //     <div key={index}>
                        //       <div className='row'>
                        //         <div className='col-md-6 col-12'>
                        //           <div className='row' style={{marginBottom: '20px'}}>
                        //             <div
                        //               className='col-sm-6 col-12'
                        //               style={{
                        //                 fontWeight: 600,
                        //                 letterSpacing: '0.5px',
                        //                 color: '#000',
                        //               }}
                        //             >
                        //               Company Name :
                        //             </div>
                        //             <div className='col-sm-6 col-12' style={{}}>
                        //               {item.company_name ? item.company_name : '-'}
                        //             </div>
                        //           </div>
                        //         </div>
                        //         <div className='col-md-6 col-12'>
                        //           <div className='row' style={{marginBottom: '20px'}}>
                        //             <div
                        //               className='col-sm-6 col-12'
                        //               style={{
                        //                 fontWeight: 600,
                        //                 letterSpacing: '0.5px',
                        //                 color: '#000',
                        //               }}
                        //             >
                        //               Contact Person Name
                        //             </div>
                        //             <div className='col-sm-6 col-12' style={{}}>
                        //               {' '}
                        //               {item.contact_person_name ? item.contact_person_name : ''}
                        //             </div>
                        //           </div>
                        //         </div>
                        //       </div>
                        //       <div className='row' key={index}>
                        //         <div className='col-md-6 col-12'>
                        //           <div className='row' style={{marginBottom: '20px'}}>
                        //             <div
                        //               className='col-sm-6 col-12'
                        //               style={{
                        //                 fontWeight: 600,
                        //                 letterSpacing: '0.5px',
                        //                 color: '#000',
                        //               }}
                        //             >
                        //               Email :
                        //             </div>
                        //             <div className='col-sm-6 col-12' style={{}}>
                        //               {item.email ? item.email : '-'}
                        //             </div>
                        //           </div>
                        //         </div>
                        //         <div className='col-md-6 col-12'>
                        //           <div className='row' style={{marginBottom: '20px'}}>
                        //             <div
                        //               className='col-sm-6 col-12'
                        //               style={{
                        //                 fontWeight: 600,
                        //                 letterSpacing: '0.5px',
                        //                 color: '#000',
                        //               }}
                        //             >
                        //               Phone
                        //             </div>
                        //             <div className='col-sm-6 col-12' style={{}}>
                        //               {' '}
                        //               {item.phone ? item.phone : ''}
                        //             </div>
                        //           </div>
                        //         </div>
                        //       </div>
                        //     </div>
                        //     <hr></hr>
                        //     </>
                        //   ))}
                        // </>
                        <>
                          <div style={{overflowX: 'auto'}}>
                            <ListTable
                              rowKey={(record: {_id: any}) => record._id}
                              onChange={() => {}}
                              onPaginationChange={(e: any) => {}}
                              column={this.tradecolumns}
                              paginationConfig={{
                                total: this.props.data.trade_references.length,
                                showSizeChanger: false,
                                pageSize: 10000,
                                current: 1,
                              }}
                              isLoading={false}
                              dataSource={this.props.data.trade_references}
                              counter={this.trade_ref_counter}
                              tempCounter={this.trade_ref_tempCounter}
                              isScroll={true}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                        // <div>No References Found</div>
                      )}

                      {/* <div style={{marginBottom: '30px'}}>
                        <div className='col-lg-6 col-md-12 col-sm-12' style={{padding: 0}}>
                          <div style={{fontSize: '20px', fontWeight: 500}}>Owners Details : </div>
                        </div>
                      </div> */}
                      {/* {this.props.data.order_authorized_person ? (
                        <>
                          <div style={{overflowX: 'auto'}}>
                            <ListTable
                              rowKey={(record: {_id: any}) => record._id}
                              onChange={() => {}}
                              onPaginationChange={(e: any) => {}}
                              column={this.ownerscolumns}
                              paginationConfig={{
                                total: this.props.data.order_authorized_person.length,
                                showSizeChanger: false,
                                pageSize: 10000,
                                current: 1,
                              }}
                              isLoading={false}
                              dataSource={this.props.data.order_authorized_person}
                              counter={this.owners_counter}
                              tempCounter={this.owners_tempCounter}
                              isScroll={true}
                            />
                          </div>
                        </>
                      ) : (
                        <div>No Owners Found</div>
                      )} */}

                      <div className='d-flex align-items-center'>
                        {/* <Button type="primary" onClick={handleSubmit} danger style={{marginRight:"20px",width:"85px",height:"35px",borderRadius:"8px"}}>Decline</Button>
                      <InputButtonComponent onClick={handleSubmit}>Approve</InputButtonComponent> */}
                        {/* <Button
                          type='primary'
                          // onClick={() => this.handleSubmit(values, handleSubmit, false)}
                          danger
                          disabled={!this.props.data || !this.props.data.document_url}
                          style={{
                            marginRight: '20px',
                            width: '85px',
                            height: '35px',
                            borderRadius: '8px',
                          }}
                          onClick={() => {
                            this.showReasonModalModal(values)
                          }}
                        >
                          Decline
                        </Button> */}
                        {/* <Button
                          type='primary'
                          // onClick={() => this.handleSubmit(values, handleSubmit, false)}
                          primary
                          style={{
                            marginRight: '20px',
                            width: '85px',
                            height: '35px',
                            borderRadius: '8px',
                          }}
                          onClick={() => {
                            this.showReasonModalModal(values)
                          }}
                        >
                          Approve2
                        </Button> */}
                        {/* <Popconfirm
                          onConfirm={() => {
                            this.handleSubmit(values, handleSubmit, true)
                          }}
                          title='Are you sure you want to Approve Kyc Request?'
                        >
                          <InputButtonComponent
                            disabled={!this.props.data || !this.props.data.document_url}
                            onClick={() => {}}
                          >
                            Approve
                          </InputButtonComponent>
                        </Popconfirm> */}
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
            <CommanModal
              show={kycReasonModal ? true : false}
              handleClose={() => {
                this.closeReasonModal()
              }}
              title={'Kyc Reject Reson'}
              submitText={'Save Changes'}
              isScrollable={true}
            >
              <div>
                {kycReasonModal && (
                  <RejectReasonForm
                    data={kycReasonModal}
                    handleCancel={() => {
                      this.closeReasonModal()
                    }}
                    kycRejectedCloseModal={() => {
                      this.props.handleCancel()
                    }}
                  />
                )}
              </div>
            </CommanModal>
            {/* 
              <CommanModal
                    show={kycSelectedData ? true : false}
                    handleClose={() => {
                      this.closeDocumentsModal()
                    }}
                    title={'Kyc Documents'}
                    submitText={'Save Changes'}
                    isScrollable={true}
                  >
                    <div>
                      {kycSelectedData && (
                        <KycDocumentForm
                          data={kycSelectedData}
                          handleCancel={() => {
                            this.closeDocumentsModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal> */}
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
