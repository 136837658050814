/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  console.log(user, 'admin')
  const {role, permission} = user
  return (
    <>
      {/* Dashboard module */}
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {/* Kyc Module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.kyc) ? (
        <>
          {' '}
          <AsideMenuItemWithSub
            to='/kyc'
            title={intl.formatMessage({id: 'MENU.KYC'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/kyc/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/kycVendor'
            title={intl.formatMessage({id: 'MENU.VENDORKYC'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/kycVendor/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </>
      ) : null}

      {/* Inventory module */}
      {role === 'super_admin' ||
      (role === 'sub_admin' && permission && permission.list_inventory) ? (
        <AsideMenuItemWithSub
          to='/inventory'
          title={intl.formatMessage({id: 'MENU.INVENTORY'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/inventory/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          {/* Subadmin cannot import inventory */}
          {role !== 'sub_admin' && (
            <AsideMenuItem
              to='/inventory/import'
              title={intl.formatMessage({id: 'MENU.IMPORTINVENTORY'})}
              hasBullet={true}
            />
          )}
        </AsideMenuItemWithSub>
      ) : null}

      {/* Stock Offer Module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.stock_offer) ? (
        <AsideMenuItemWithSub
          to='/clientoffer'
          title={intl.formatMessage({id: 'MENU.CLIENTOFFER'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/clientoffer/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {/* Buy Request Module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.buyrequest) ? (
        <AsideMenuItemWithSub
          to='/buy-request'
          title={intl.formatMessage({id: 'MENU.BUYREQUEST'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/buy-request/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {/* Order Module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.order) ? (
        <AsideMenuItemWithSub
          to='/order'
          title={intl.formatMessage({id: 'MENU.ORDER'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/order/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {/* Vendor Order Module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.vendororder) ? (
        <AsideMenuItemWithSub
          to='/vendor-order'
          title={intl.formatMessage({id: 'MENU.VENDORORDERADMIN'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/vendor-order/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {/* Return Order Module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.return_order) ? (
        <AsideMenuItemWithSub
          to='/returnorder'
          title={intl.formatMessage({id: 'MENU.RETURNORDER'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/returnorder/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {/* Vendor Decline Module */}
      {role === 'super_admin' ||
      (role === 'sub_admin' && permission && permission.declinestocks) ? (
        <AsideMenuItemWithSub
          to='/decline-stock'
          title={intl.formatMessage({id: 'MENU.DECLINESTOCKS'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/decline-stock/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {/* Users module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.user) ? (
        <AsideMenuItemWithSub
          to='/users'
          title={intl.formatMessage({id: 'MENU.CLIENT'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/users/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          {/* <AsideMenuItem
              to='/users/create'
              title={intl.formatMessage({id: 'MENU.CREATE'})}
              hasBullet={true}
            /> */}
        </AsideMenuItemWithSub>
      ) : null}

      {/* Vendors module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.vendors) ? (
        <AsideMenuItemWithSub
          to='/vendors'
          title={intl.formatMessage({id: 'MENU.VENDOR'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/vendors/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/vendors/create'
            title={intl.formatMessage({id: 'MENU.CREATE'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {/* Sales Report Module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.sales) ? (
        <AsideMenuItemWithSub
          to='/sales-report'
          title={intl.formatMessage({id: 'MENU.SALESREPORT'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/sales-report/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {/* Chats module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.chats) ? (
        <>
          <AsideMenuItemWithSub
            to='/chats'
            title={intl.formatMessage({id: 'MENU.CHATS'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/chats/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </>
      ) : null}

      {/* Sub Admin module */}
      {role === 'super_admin' && (
        <>
          {' '}
          <AsideMenuItemWithSub
            to='/subadmin'
            title={intl.formatMessage({id: 'MENU.SUBADMIN'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/subadmin/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/subadmin/create'
              title={intl.formatMessage({id: 'MENU.CREATE'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/jewelry-order'
            title={intl.formatMessage({id: 'MENU.JEWELRYORDERS'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/jewelry-order/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/melee-order'
            title={intl.formatMessage({id: 'MENU.MELEEORDERS'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/melee-order/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </>
      )}

      {/* Static Page Module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.static_page) ? (
        <AsideMenuItemWithSub
          to='/static'
          title={intl.formatMessage({id: 'MENU.STATICPAGE'})}
          fontIcon='bi-app-indicator'
          icon='/media/icons/duotune/art/art002.svg'
        >
          <AsideMenuItem
            to='/static/list'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/static/create'
            title={intl.formatMessage({id: 'MENU.CREATE'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {/* Banner Module */}
      {role === 'super_admin' || (role === 'sub_admin' && permission && permission.banner) ? (
        <>
          <AsideMenuItemWithSub
            to='/banner'
            title={intl.formatMessage({id: 'MENU.BANNER'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/banner/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/banner/create'
              title={intl.formatMessage({id: 'MENU.CREATE'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </>
      ) : null}

      {/* VENDOR ROLE MENU   */}
      {role === 'vendor' ? (
        <>
          {/* Inventory Module */}
          <AsideMenuItemWithSub
            to='/inventory'
            title={intl.formatMessage({id: 'MENU.INVENTORY'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/inventory/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
            {/* Subadmin cannot import inventory */}
            {role !== 'sub_admin' && (
              <AsideMenuItem
                to='/inventory/import'
                title={intl.formatMessage({id: 'MENU.IMPORTINVENTORY'})}
                hasBullet={true}
              />
            )}
          </AsideMenuItemWithSub>

          {/* Stock Offer */}
          <AsideMenuItemWithSub
            to='/clientoffer'
            title={intl.formatMessage({id: 'MENU.CLIENTOFFER'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/clientoffer/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>

          {/* Hold Requests Module */}
          <AsideMenuItemWithSub
            to='/vendor-stock'
            title={intl.formatMessage({id: 'MENU.STOCKREQUEST'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/vendor-stock/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>

          {/* Order Module */}
          <AsideMenuItemWithSub
            to='/vendor-order'
            title={intl.formatMessage({id: 'MENU.VENDORORDER'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/vendor-order/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>

          {/* Return Order Module */}
          <AsideMenuItemWithSub
            to='/returnorder'
            title={intl.formatMessage({id: 'MENU.RETURNORDER'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/art/art002.svg'
          >
            <AsideMenuItem
              to='/returnorder/list'
              title={intl.formatMessage({id: 'MENU.LIST'})}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </>
      ) : null}
    </>
  )
}
