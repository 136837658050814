import React, {useState, useEffect} from 'react'
import {CommanModal} from '../../modules/comman/components/ModalComponent'
import {InputButtonComponent} from '../../modules/comman/formComponents/InputButtonComponent'
import VendorDetailsForm from './VendorDetailsForm'
import {RootState} from '../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import {getTermsAndConditionData} from '../../routing/redux/userCRUD'

export const VendorTermsForm = () => {
  const isAuthorized: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [termsData, setTermsData] = useState<any>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {data} = await getTermsAndConditionData()
        console.log({data})
        setTermsData(data)
      } catch (error) {
        console.error('Error fetching KYC data:', error)
      } finally {
      }
    }
    fetchData()
  }, [])

  const [showForm, setShowForm] = useState(false)
  const [showModal, setShowModal] = useState(true)
  {
    console.log('inside terms', isAuthorized)
  }

  return (
    <CommanModal
      show={showModal}
      handleClose={() => {}}
      title={showForm ? 'Fill Up Your Details' : 'Terms And Conditons'}
      submitText={''}
      isScrollable={true}
    >
      {showForm ? (
        <VendorDetailsForm
          handleCancel={() => {
            setShowModal(false)
          }}
          termsPage={() => {
            setShowForm(false)
          }}
        />
      ) : (
        <>
          <div dangerouslySetInnerHTML={{__html: termsData?.content}} />
          <div className='card-footer'>
            <div className='d-flex justify-content-end'>
              <InputButtonComponent
                onClick={() => {
                  setShowForm(true)
                }}
              >
                Next
              </InputButtonComponent>
            </div>
          </div>
        </>
      )}
    </CommanModal>
  )
}
