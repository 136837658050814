import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../modules/comman/formComponents/InputButtonComponent'
import {InputNumberText} from '../../modules/comman/formComponents/InputNumberText'
import {Toaster} from '../../modules/comman/components/ToasterNoti'
import {vendorDetailsAndTermsConditionData} from '../vendors/redux/VendorsCRUD'
import {Spin} from 'antd'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import * as auth from '../../modules/auth/redux/AuthRedux'
import {History, LocationState} from 'history'
import {KycUpload} from '../setting/containers/KycUpload'

interface IRenderFormikForm {
  values: IClientFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IClientFormValues {
  bank_name: string
  acc_holder_name: string
  acc_number: number
  ifsc_code: string
  street: string
  city: string
  state: string
  country: string
  zip_code: number
  id: string
}

interface IAddClientProps {
  onSubmit?: any
  data?: any
  handleCancel: any
  termsPage: any
  setLoginData: any
  loginUser: any
  accessToken: any
  history?: History<LocationState>
}

class VendorDetailsForm extends React.Component<IAddClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IClientFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.bank_name) {
      errors.bank_name = 'Bank Name is required'
    }

    if (!values.acc_holder_name) {
      errors.acc_holder_name = 'Account Holder Name is required'
    }

    if (!values.acc_number) {
      errors.acc_number = 'Account Number is required'
    }

    if (!values.ifsc_code) {
      errors.ifsc_code = 'IFSC Code is required'
    }

    if (!values.street) {
      errors.street = 'Street is required'
    }

    if (!values.city) {
      errors.city = 'City is required'
    }

    if (!values.state) {
      errors.state = 'State is required'
    }

    if (!values.country) {
      errors.country = 'Country is required'
    }

    if (!values.zip_code) {
      errors.zip_code = 'Zip Code is required'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = async (values: IClientFormValues, action: any) => {
    try {
      const {setLoginData} = this.props
      const {history} = this.props

      // console.log('Basic Value - ', values)
      // this.props.onSubmit(values)
      // this.props.handleCancel()

      let vendorData: any = {}
      vendorData.billing_details = {
        bank_name: values.bank_name,
        account_holder_name: values.acc_holder_name,
        account_number: values.acc_number,
        ifsc: values.ifsc_code,
      }

      vendorData.address = {
        street: values.street,
        city: values.city,
        state: values.state,
        country: values.country,
        zip_code: values.zip_code,
      }
      vendorData.is_terms_accepted = true
      vendorData.id = values.id
      this.setState({
        isLoading: true,
      })
      let tempData = {...this.props.loginUser, is_terms_accepted: true}

      setTimeout(async () => {
        let termsVendorData = await vendorDetailsAndTermsConditionData(vendorData)

        if (termsVendorData && termsVendorData.status === 200) {
          await setLoginData(this.props.accessToken, tempData)
          // if (history) {
          //   history.push('/dashboard')
          // }
          Toaster({
            type: 'success',
            title: 'VENDORS',
            description: 'Saved SuccessFully',
          })

          await document.location.reload()
          await this.props.handleCancel()
          this.setState({
            visible: false,
          })
        }
      }, 500)
    } catch (e) {
      console.log(e)
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div className='card-body'>
        <div>
          <Formik
            initialValues={{
              bank_name: '',
              acc_holder_name: '',
              acc_number: NaN,
              ifsc_code: '',
              street: '',
              city: '',
              state: '',
              country: '',
              zip_code: NaN,
              id: this.props.loginUser && this.props.loginUser.id ? this.props.loginUser.id : '',
            }}
            validate={this.validateForm}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }: IRenderFormikForm) => {
              return (
                <Spin spinning={this.state.isLoading} tip='Loading...'>
                  <div className='table-form'>
                    <div className='form-group row'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputText
                          input={{
                            value: values.bank_name,
                            id: 'bank_name',
                            name: 'bank_name',
                          }}
                          placeholder='Enter Bank Name'
                          onChange={(value: string) => {
                            handleChange(value)
                            setFieldValue('bank_name', value)
                          }}
                          onBlur={handleBlur}
                          label='Bank Name'
                          error={errors}
                          touched={touched}
                        />
                      </div>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputText
                          input={{
                            value: values.acc_holder_name,
                            id: 'acc_holder_name',
                            name: 'acc_holder_name',
                          }}
                          placeholder='Enter Account Holder Name'
                          onChange={(value: string) => {
                            handleChange(value)
                            setFieldValue('acc_holder_name', value)
                          }}
                          onBlur={handleBlur}
                          label='Account Holder Name'
                          error={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputNumberText
                          input={{
                            value: values.acc_number,
                            id: 'acc_number',
                            name: 'acc_number',
                          }}
                          placeholder='Enter Account Number'
                          onChange={(value: number) => {
                            handleChange(value)
                            setFieldValue('acc_number', value)
                          }}
                          onBlur={handleBlur}
                          label='Account Number'
                          error={errors}
                          touched={touched}
                        />
                      </div>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputText
                          input={{
                            value: values.ifsc_code,
                            id: 'ifsc_code',
                            name: 'ifsc_code',
                          }}
                          placeholder='Enter IFSC Code'
                          onChange={(value: string) => {
                            handleChange(value)
                            setFieldValue('ifsc_code', value)
                          }}
                          onBlur={handleBlur}
                          label='IFSC Code'
                          error={errors}
                          touched={touched}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputText
                          input={{
                            value: values.street,
                            id: 'street',
                            name: 'street',
                          }}
                          placeholder='Enter Street'
                          onChange={(value: string) => {
                            handleChange(value)
                            setFieldValue('street', value)
                          }}
                          onBlur={handleBlur}
                          label='Street'
                          error={errors}
                          touched={touched}
                          required={false}
                        />
                      </div>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputText
                          input={{
                            value: values.city,
                            id: 'city',
                            name: 'city',
                          }}
                          placeholder='Enter city'
                          onChange={(value: string) => {
                            handleChange(value)
                            setFieldValue('city', value)
                          }}
                          onBlur={handleBlur}
                          label='City'
                          error={errors}
                          touched={touched}
                          required={false}
                        />
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputText
                          input={{
                            value: values.state,
                            id: 'state',
                            name: 'state',
                          }}
                          placeholder='Enter state'
                          onChange={(value: string) => {
                            handleChange(value)
                            setFieldValue('state', value)
                          }}
                          onBlur={handleBlur}
                          label='State'
                          error={errors}
                          touched={touched}
                          required={false}
                        />
                      </div>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputText
                          input={{
                            value: values.country,
                            id: 'country',
                            name: 'country',
                          }}
                          placeholder='Enter country'
                          onChange={(value: string) => {
                            handleChange(value)
                            setFieldValue('country', value)
                          }}
                          onBlur={handleBlur}
                          label='Country'
                          error={errors}
                          touched={touched}
                          required={false}
                        />
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputNumberText
                          input={{
                            value: values.zip_code,
                            id: 'zip_code',
                            name: 'zip_code',
                          }}
                          placeholder='Enter Zip code'
                          onChange={(value: number) => {
                            handleChange(value)
                            setFieldValue('zip_code', value)
                          }}
                          onBlur={handleBlur}
                          label='Zip Code'
                          error={errors}
                          touched={touched}
                          required={false}
                        />
                      </div>
                    </div>
                    <div className='card-footer'>
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-sm'
                          onClick={() => {
                            this.props.termsPage()
                          }}
                          style={{
                            cursor: 'pointer',
                            border: 'none',
                            backgroundColor: '#8B93FF',
                            color: 'white',
                            marginRight: '20px',
                          }}
                        >
                          {'Back'}
                        </button>
                        <InputButtonComponent onClick={handleSubmit}>Save</InputButtonComponent>
                      </div>
                    </div>
                  </div>
                </Spin>
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  accessToken: state.auth.accessToken,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoginData: (authToken: any, adminData: any) => {
      dispatch(auth.actions.login(authToken, adminData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorDetailsForm)
