/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as kyc from '../redux/KycRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, DollarOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import {getUsersData, getSearchKycData, getAvailableCreditsData} from '../redux/KycCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
// import {EditOrderForm} from './EditOrderForm'
// import {PreviewOrderForm} from './PreviewOrderForm'
// import {IOrderFormValues} from './CreateOrderForm'
import moment from 'moment'
import {Tag} from 'antd'
import {returnStockId} from '../../../comman/helper'
import {KycDocumentForm} from './KycDocumentForm'
import {EditKycDocumentForm} from './EditKycDocumentForm'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDollarSign} from '@fortawesome/free-solid-svg-icons'
import {Spin} from 'antd'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  kycData: any
  kycDataTotal: number
  kycLoading: boolean
  setListKycData: (kycData: any) => void
  setListKycDataTotal: (kycDataTotal: number) => void
  setKycLoading: (kycLoading: boolean) => void
  history?: History<LocationState>
  loginUser?: any
  tabkey?: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  kycSelectedData: any
  editkycSelectedData: any
  loaderForId: any
  mapAvailableCredits: any
}

type PathParamsType = {
  param1: string
}
class ListKycUserData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      kycSelectedData: null,
      editkycSelectedData: null,
      loaderForId: null,
      mapAvailableCredits: {},
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListKycData(pagination)
    )
  }

  public getListKycData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listKycSearchData(start, end)
        } else {
          this.listKycData(start, end)
        }
      }
    )
  }

  public listKycData = async (start: number, end: any) => {
    const {setKycLoading, tabkey} = this.props
    try {
      const {setListKycData, setListKycDataTotal} = this.props
      await setKycLoading(true)
      let kycData = await getUsersData(start, end, tabkey == 1 ? false : true)
      if (kycData.data) {
        await setListKycData(kycData.data)
        await setListKycDataTotal(kycData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setKycLoading(false)
    }
  }

  public listKycSearchData = async (start: number, end: any) => {
    const {setKycLoading, tabkey} = this.props
    try {
      const {setListKycData, setListKycDataTotal} = this.props
      const {searchText} = this.state

      await setKycLoading(true)
      let kycData = await getSearchKycData(start, end, searchText, tabkey == '1' ? false : true)
      if (kycData.data) {
        await setListKycData(kycData.data)
        await setListKycDataTotal(kycData.count)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setKycLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listKycSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public showDocumentsModal = async (item: any) => {
    this.setState({
      kycSelectedData: item,
    })
  }

  public closeDocumentsModal = async () => {
    this.setState({
      kycSelectedData: null,
    })
    if (this.state.searchText) {
      this.listKycSearchData(this.state.startIndex, this.state.limitNumber)
    }
    this.listKycData(this.state.startIndex, this.state.limitNumber)
  }

  public showEditModal = async (item: any) => {
    this.setState({
      editkycSelectedData: item,
    })
  }

  public closeEditModal = async (id?: any) => {
    this.setState({
      editkycSelectedData: null,
    })
    if (this.state.searchText) {
      await this.listKycSearchData(this.state.startIndex, this.state.limitNumber)
    }
    await this.listKycData(this.state.startIndex, this.state.limitNumber)
    if (id) {
      await this.checkAvailableCredits(id)
    }
  }

  public checkAvailableCredits = async (id: any) => {
    try {
      this.setState({
        loaderForId: id,
      })
      let creditsData = await getAvailableCreditsData(id)
      if (creditsData) {
        this.setState((prevState: any) => ({
          mapAvailableCredits: {
            ...prevState.mapAvailableCredits,
            [id]: creditsData.data ? creditsData.data.toFixed(2) : 0,
          },
        }))
      }
    } catch (e) {
    } finally {
      this.setState({
        loaderForId: null,
      })
    }
  }

  counter = 0
  tempCounter = 0
  public columns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Full Name',
      key: 'Full Name',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.first_name && item.last_name
                ? `${item.first_name} ${item.last_name}`
                : item.first_name
                ? item.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      width: 250,
      render: (text: any, item: any, index: number) => {
        return <div>{item.email ? item.email : '-'}</div>
      },
    },
    // {
    //   title: 'Company Name',
    //   key: 'company_name',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.company_name ? item.company_name : '-'}</div>
    //   },
    // },
    {
      title: 'Phone',
      key: 'phone',
      render: (text: any, item: any, index: number) => {
        return <div>{item.phone ? item.phone : '-'}</div>
      },
    },
    // {
    //   title: 'Credit Limit',
    //   key: 'credit_limit',
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.credit_limit ? item.credit_limit : '0'}</div>
    //   },
    // },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, item: any, index: number) => {
        const {mapAvailableCredits, loaderForId} = this.state
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            {this.props.tabkey == '2' &&
            mapAvailableCredits &&
            (mapAvailableCredits[item.id] || mapAvailableCredits[item.id] === 0) ? (
              <div
                onClick={() => {
                  this.checkAvailableCredits(item.id)
                }}
                style={{float: 'left', marginRight: '5px', cursor: 'pointer'}}
              >
                {' '}
                {loaderForId && loaderForId == item.id ? (
                  <Spin style={{position: 'relative', top: '5px'}} spinning={loaderForId}></Spin>
                ) : (
                  <Tooltip placement='bottom' title='Revaluate Credit'>
                    <span
                      style={{position: 'relative', top: '5px', color: '#1ed83d', fontWeight: 500}}
                    >
                      ${mapAvailableCredits[item.id]}
                    </span>
                  </Tooltip>
                )}
              </div>
            ) : (
              this.props.tabkey == '2' && (
                <div style={{float: 'left', marginRight: '5px'}}>
                  {loaderForId && loaderForId == item.id ? (
                    <Spin style={{position: 'relative', top: '5px'}} spinning={loaderForId}></Spin>
                  ) : (
                    <Tooltip placement='bottom' title='Available Credit'>
                      <Button
                        shape='circle'
                        icon={<EyeOutlined />}
                        onClick={() => {
                          this.checkAvailableCredits(item.id)
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              )
            )}
            {this.props.tabkey == '1' ? (
              <div style={{float: 'left', marginRight: '5px'}}>
                <Tooltip placement='bottom' title='Preview'>
                  <Button
                    shape='circle'
                    icon={<EyeOutlined />}
                    onClick={() => {
                      this.showDocumentsModal(item)
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <div
                style={{
                  float: mapAvailableCredits && mapAvailableCredits[item.id] ? 'right' : 'left',
                  marginRight: '5px',
                }}
              >
                <Tooltip placement='bottom' title='Add'>
                  <Button
                    shape='circle'
                    icon={<FontAwesomeIcon icon={faDollarSign} style={{color: '#1c1c1c'}} />}
                    onClick={() => {
                      this.showEditModal(item)
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  public render() {
    const {kycData, kycDataTotal, kycLoading, loginUser} = this.props
    const {searchText, kycSelectedData, editkycSelectedData, mapAvailableCredits} = this.state

    const columnExists = (key: any) => {
      return this.columns.some((column) => column.key === key)
    }

    if (!columnExists('reason') && this.props.tabkey == '1') {
      this.columns.splice(-1, 0, {
        title: 'Reason',
        key: 'reason',
        render: (text: any, item: any, index: number) => {
          return (
            <Tooltip placement='bottom' title={item && item.kyc_reject_reason ? item.kyc_reject_reason : '-'}>
              <div className='d-inline-block text-truncate' style={{maxWidth: '100px'}}>
                {' '}
                {item && item.kyc_reject_reason ? item.kyc_reject_reason : '-'}
              </div>
            </Tooltip>
          )
        },
      })
    }

    return (
      <div>
        <div>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title table-card-title' style={{width: '25%'}}>
                <InputSearchText
                  onChange={(value: string) => {
                    this.onSearchChange(value)
                  }}
                  placeholder='Search Vendor'
                  input={{
                    value: searchText,
                  }}
                />
              </h3>
            </div>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  column={this.columns}
                  paginationConfig={{
                    total: kycDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={kycLoading}
                  dataSource={kycData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={false}
                />
                <div>
                  {/* <CommanModal
                    show={kycSelectedData ? true : false}
                    handleClose={() => {
                      this.closeDocumentsModal()
                    }}
                    title={'Edit Banner'}
                    submitText={'Save Changes'}
                    isScrollable={true}
                  >
                    <div>
                      {kycSelectedData && (
                        <KycDocumentForm
                          data={kycSelectedData}
                          handleCancel={() => {
                            this.closeDocumentsModal()
                          }}
                          onSubmit={() => {}}
                        />
                      )}
                    </div>
                  </CommanModal> */}
                  <CommanModal
                    show={kycSelectedData ? true : false}
                    handleClose={() => {
                      this.closeDocumentsModal()
                    }}
                    title={'Kyc Documents'}
                    submitText={'Save Changes'}
                    isScrollable={true}
                  >
                    <div>
                      {kycSelectedData && (
                        <KycDocumentForm
                          data={kycSelectedData}
                          handleCancel={() => {
                            this.closeDocumentsModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                  <CommanModal
                    show={editkycSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Add Credit Limit'}
                    submitText={'Save Changes'}
                    isScrollable={true}
                  >
                    <div>
                      {editkycSelectedData && (
                        <EditKycDocumentForm
                          data={editkycSelectedData}
                          handleCancel={(id?: any) => {
                            this.closeEditModal(id)
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  kycData: state.kyc.kycData,
  kycDataTotal: state.kyc.kycDataTotal,
  kycLoading: state.kyc.kycLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListKycData: (kycData: any) => {
      dispatch(kyc.actions.setKycData(kycData))
    },
    setListKycDataTotal: (kycDataTotal: number) => {
      dispatch(kyc.actions.setKycDataTotal(kycDataTotal))
    },
    setKycLoading: (kycLoading: boolean) => {
      dispatch(kyc.actions.setKycLoading(kycLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListKycUserData))
