import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_VENDORS_URL = `${API_URL}api/v1/auth/admin/vendor`
export const KYC_VERIFY_VENDOR_STATUS_URL = `${API_URL}api/v1/auth/admin/vendor/verify`
export const EDIT_KYC_DATA_URL = `${API_URL}api/v1/auth/admin/user/update-credit-limit`
export const GET_AVAILABLE_CREDITS_URL = `${API_URL}api/v1/auth/admin/available-credit`

interface ListType {
  count?: any
  data: any
}

export async function getUsersData(startIndex: number, limitNumber: number, verified: boolean) {
  try {
    let usersData: ListType = await axios.get(
      `${GET_VENDORS_URL}?skip=${startIndex}&limit=${limitNumber}&is_verified=${verified}`
    )
    return usersData
  } catch (e) {
    throw e
  }
}

export async function getSearchKycData(
  startIndex: number,
  limitNumber: number,
  searchtext: string,
  verified: boolean
) {
  try {
    let usersData: ListType = await axios.get(
      `${GET_VENDORS_URL}?skip=${startIndex}&limit=${limitNumber}&q=${searchtext}&is_verified=${verified}`
    )
    return usersData
  } catch (e) {
    throw e
  }
}

export async function verifyKycStatus(values: any) {
  try {
    let userKycData = await axios.put(`${KYC_VERIFY_VENDOR_STATUS_URL}`, {
      ...values,
    })
    return userKycData
  } catch (e) {
    throw e
  }
}

export async function editKycData(values: any) {
  try {
    let editKycData = await axios.put(`${EDIT_KYC_DATA_URL}`, {
      ...values,
    })
    return editKycData
  } catch (e) {
    throw e
  }
}

export async function getAvailableCreditsData(id: any) {
  try {
    let usersData: ListType = await axios.get(
      `${GET_AVAILABLE_CREDITS_URL}?user_id=${id}`
    )
    return usersData
  } catch (e) {
    throw e
  }
}
